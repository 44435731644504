<template>
    <v-tab-item key="measurementsAction">
        <v-card tile class="elevation-0">
<!--            <v-list dense>-->
<!--                <v-list-item >-->
<!--                    <v-list-item-icon>-->
<!--                        <v-icon color="success">check</v-icon>-->
<!--                    </v-list-item-icon>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title class=""-->
<!--                            >Dépôt reçu/autorisé</v-list-item-title-->
<!--                        >-->
<!--                        <span class="caption"-->
<!--                            >19 septembre 2019 à 14:43-->
<!--                        </span>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->
<!--                <v-list-item >-->
<!--                    <v-list-item-icon>-->
<!--                        <v-icon color="success">check</v-icon>-->
<!--                    </v-list-item-icon>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title-->
<!--                            >Paiement(s) complet(s)</v-list-item-title-->
<!--                        >-->
<!--                        <span class="caption"-->
<!--                            >19 septembre 2019 à 14:43-->
<!--                        </span>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->
<!--                <v-list-item >-->
<!--                    <v-list-item-icon>-->
<!--                        <v-icon color="success">check</v-icon>-->
<!--                    </v-list-item-icon>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>Projet facturé</v-list-item-title>-->
<!--                        <span class="caption"-->
<!--                            >19 septembre 2019 à 14:43-->
<!--                        </span>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->
<!--            </v-list>-->

<!--            <v-divider></v-divider>-->
                        <files-list :files="project.documents"></files-list>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click.stop="dialogs.revisionRequest = true"
                    >Demander une révision
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="dialogs.revisionRequest">
            <v-card tile>
                <v-card-title>
                    Demande de révision pour projet {{ project.quote_number }}
                </v-card-title>
                <v-card-text>
                    <v-select
                        :items="selects.revisionRequestAssignee"
                        label="Assigner à"
                        v-model="selected.revisionRequestAssignee"
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click.stop="dialogs.revisionRequest = false"
                        >Fermer
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        @click.stop="assignRevisionRequest()"
                        >Assigner
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-tab-item>
</template>

<script>
import FilesList from "~/components/files/FilesList";
// import draggable from 'vuedraggable';

export default {
    components: {
        filesList: FilesList,
        // draggable
    },
    props: {
        project: Object
    },
    data() {
        return {
            dialogs: {
                revisionRequest: false
            },
            selects: {
                revisionRequestAssignee: [
                    {
                        text: 'Matthieu Lambert',
                        value: 1234
                    }
                ]
            },
            selected: {
                revisionRequestAssignee: 1234
            }
        };
    },
    methods: {
        assignRevisionRequest() {
          window.Front.assign('richard');
          window.Front.reply({
                to: ['rlavigne@gmail.com'],
                subject: 'Confirmation de mesures',
                body:
                    'Bonjour!\n' +
                    '\n' +
                    'Ci-joint est un document PDF indiquant les mesures actuelles de votre projet. SVP me confirmer ou corriger ces mesures et nous procéderons par la suite aux ajustements (si nécessaire) puis à la mise en production de votre commande.\n' +
                    '\n' +
                    'Merci!',
                attachment_uids: []
            });

            this.dialogs.revisionRequest = false;
        }
    },
    mounted() {}
};
</script>

<style></style>
