<template>
    <v-list dense class="grey lighten-4">
        <template v-for="file in files">
            <v-list-item :key="file.id">
                <v-list-item-icon>
                    <v-icon small :color="documentColor(file.filename)"
                        >{{ findMimeTypeIcon(file.mimetype) }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        <a
                            :href="process.env.VUE_APP_BOCANI_INTRA_URL + '/document/' + file.id"
                            target="_blank"
                        >{{ file.filename }}</a>
                    </v-list-item-title>
                    <span class="caption"
                        >{{ file.created_at }}
                    </span>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
    props: {
        files: Array
    },

    data() {
        return {};
    },

    methods: {
        findMimeTypeIcon(mimeType) {
            let mimeTypes = {
                // 'application/pdf':          '',
                'image/jpeg': 'photo',
                'image/tiff': 'photo',
                // 'application/vnd.ms-excel': '',
                'image/png': 'photo',
                'audio/mp3': 'audiotrack'
                // 'text/html':                '',
            };

            return typeof mimeTypes[mimeType] !== 'undefined'
                ? mimeTypes[mimeType]
                : 'insert_drive_file';
        },
        documentColor(filename) {
            if (filename.search('SK-') !== -1) {
                return 'primary';
            }

            if (filename.search('FC-') !== -1) {
                return 'success';
            }

            return 'default';
        },
        openDocument(documentId) {
            Front.openUrl(process.env.VUE_APP_BOCANI_INTRA_URL + '/document/' + documentId);
        }
    },
    mounted() {
        // console.log(this.files);
    }
};
</script>

<style lang="scss">
.list__tile__action {
    min-width: 33px !important;
}
</style>
