<template>
    <v-tab-item key="deliveryAction">
        <v-card tile class="elevation-0">
            <v-row no-gutters>
                <v-col xs12 sm6 class="">
                    <v-date-picker
                        style="border-radius:0"
                        v-model="projectRequiredDate"
                        show-current="2019-10-21"
                        locale="fr-CA"
                        full-width
                    ></v-date-picker>
                </v-col>
            </v-row>
        </v-card>
    </v-tab-item>
</template>

<script>
export default {
    components: {},
    props: {
        project: Object
    },
    data() {
        return {
            date: '2019-10-21'
        };
    },
    computed: {
        projectRequiredDate() {
            return this.project.project_required_date;
        }
    }
};
</script>
