<template>
    <v-tab-item key="hasRequiredPaidBalance">
        <v-card tile class="elevation-0">

            <v-list dense>
                <!--                <v-subheader>Hangout notifications</v-subheader>-->
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon
                            v-if="hasRequiredDeposit" color="green"
                        >check
                        </v-icon>
                        <v-icon v-else color="error">remove</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            class=""
                        >Dépôt reçu/autorisé
                        </v-list-item-title>
                        <!--                        <v-list-item-sub-title class="caption">19 septembre 2019 à 14:43</v-list-item-sub-title>-->
                    </v-list-item-content>
                    <v-list-item-icon>
                        <!--                        <v-btn small flat color="primary">Actions</v-btn>-->
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon
                            v-if="hasPaidBalance" color="green"
                        >check
                        </v-icon>
                        <v-icon v-else color="error">remove</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Paiement(s) complet(s)
                        </v-list-item-title>
                        <!--                        <v-list-item-sub-title class="caption">19 septembre 2019 à 14:43</v-list-item-sub-title>-->
                    </v-list-item-content>
                    <v-list-item-icon>
                        <!--                        <v-btn small flat color="primary">Actions</v-btn>-->
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon v-if="isInvoiced" color="green">check</v-icon>
                        <v-icon v-else color="error">remove</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Projet facturé</v-list-item-title>
                        <span
                            v-if="isInvoiced" class="caption"
                        >{{ project.invoiced_at }}
                        </span>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <!--                        <v-btn small flat color="primary">Actions</v-btn>-->
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>

            <v-row no-gutters class="grey lighten-4 pa-0 text-center">
                <v-col xs class="py-2">
                    <span class="overline">Payé</span><br/>
                    <span
                        class="body-2"
                    >{{ project.amounts.payments_total }}$</span>
                </v-col>
                <v-col xs class="py-2">
                    <span class="overline">35%</span><br/>
                    <span
                        class="body-2"
                    >{{ (project.lastrevision.total * 0.35).toFixed(2) }}$</span>
                </v-col>
                <v-col xs class="py-2">
                    <span class="overline">Sous-total</span><br/>
                    <span
                        class="body-2"
                    >{{ project.lastrevision.sub_total }}$</span>
                </v-col>
                <v-col xs class="py-2">
                    <span class="overline">Total</span><br/>
                    <span class="body-2">{{ project.lastrevision.total }}$</span>
                </v-col>
            </v-row>
        </v-card>

    </v-tab-item>
</template>

<script>
    export default {
        components : {},
        props      : {
            project : Object
        },
        data() {
            return {};
        },
        computed   : {
            hasRequiredDeposit() {
                return this.project.amounts.deposit_balance == 0;
            },
            hasPaidBalance() {
                return this.project.amounts.total_balance == 0;
            },
            isInvoiced() {
                return this.project.states.invoiced_at;
            }
        }
    };
</script>

<style lang="scss">
</style>
