import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as firebase from 'firebase';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

const firebaseConfig = {
    apiKey: 'AIzaSyBIOqZ3D_7Oz1R9Py07I1_JOM6smjYQiCE',
    authDomain: 'intra-plugin-front.firebaseapp.com',
    databaseURL: 'https://intra-plugin-front.firebaseio.com',
    projectId: 'intra-plugin-front',
    storageBucket: 'intra-plugin-front.appspot.com',
    messagingSenderId: '73759445098',
    appId: '1:73759445098:web:0d25776c484289a9a37a13'
};

firebase.initializeApp(firebaseConfig);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
