<template>
    <v-app id="app" light>

        <v-container class="pa-0" v-show="conversationExists">
            <v-row no-gutters v-show="hasProfileData">
                <v-col xs12>

                    <v-toolbar dense class="elevation-0" color="white" v-show="hasProfileData">

                        <v-toolbar-title class="subtitle-2" @click="copy(profile.customer_name.toUpperCase())">{{ profile.customer_name | uppercase }}</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-btn
                                icon
                                text
                                small
                                color="primary"
                                @click="openLink( intraBaseUrl + '/customer/' + profileId)"
                            >
                                <v-icon>open_in_new</v-icon>
                            </v-btn>
                            <v-btn icon @click="denseView = !denseView">
                                <v-icon v-show="!denseView">view_headline</v-icon>
                                <v-icon v-show="denseView">view_day</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-divider></v-divider>
                    <div
                        v-for="(quoteStatus, quoteStatusKey) in profile.quotes"
                        :key="quoteStatusKey"
                        v-show="quoteStatus.length > 0"
                    >

                        <v-subheader class="subtitle-2"> {{ labels.quote.states[quoteStatusKey] | uppercase }}</v-subheader>

                        <div class="mx-2">
                            <v-card
                                tile
                                class="mb-3"
                                :class="'quote-status-border ' + quote.states.status"
                                v-for="(quote, quoteKey) in quoteStatus"
                                :key="quoteKey"
                            >

                                <v-system-bar
                                    v-if="quote.is_self_served"
                                    dark
                                    :color="colors.quote.statesBg[quote.states.status]"
                                    :class="'text-xs-center caption ' + colors.quote.statesText[quote.states.status] + '--text'">
                                    Projet libre-service
                                </v-system-bar>
                                <v-toolbar dense class="elevation-0">

                                    <v-toolbar-title class="subtitle-1 font-weight-medium" @click="copy(quote.quote_number.toUpperCase())">
                                        {{ quote.quote_number }}
                                    </v-toolbar-title>

                                    <v-spacer></v-spacer>

                                    <v-toolbar-title class="body-2 mr-2">
                                            <span class="caption">
                                                {{ quote.amounts.payments_total }}$ /
                                            </span>
                                        {{ quote.lastrevision.total }}$
                                    </v-toolbar-title>

                                    <v-toolbar-items>
                                        <v-btn
                                            icon
                                            text
                                            small
                                            color="primary"
                                            @click="openLink(intraBaseUrl + '/quote/' + quote.id)"
                                        >
                                            <v-icon>open_in_new</v-icon>
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>

                                <v-divider></v-divider>

                                <div class="caption py-2 px-3">
                                    <v-chip
                                        v-for="(tag, tagKey) in quote.main_components_tags"
                                        class="mr-1"
                                        label
                                        :key="tagKey"
                                        outlined
                                        x-small
                                        @click="copy(tag.display_label.toUpperCase())"
                                    >
                                        {{ tag.display_label | uppercase }}
                                    </v-chip>
                                </div>

                                <v-divider></v-divider>

                                <project-required-actions-panel
                                    v-show="!denseView"
                                    :is="'project-required-actions-' + quote.states.status + '-panel'"
                                    :project="quote"
                                ></project-required-actions-panel>
                            </v-card>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row v-show="hasProfileData && profile.quotesTotal == 0">
                <v-col class="text-center">
                    Aucun projet
                </v-col>
            </v-row>

            <new-profile-dialog v-show="!hasProfileData"></new-profile-dialog>
        </v-container>


    </v-app>
</template>

<script>
    import NewProfileDialog from '~/components/profiles/NewProfileDialog';
    import RAStartedPanel   from '~/components/requiredactions/panels/StartedPanel';
    // import RAAssembledPanel from "./requiredactions/panels/AssembledPanel";
    // import RAOrderedPanel from "./requiredactions/panels/OrderedPanel";
    // import RADraftPanel from "./requiredactions/panels/DraftPanel";
    // import RAFulfilledPanel from "./requiredactions/panels/FulfilledPanel";
    // import RACancelledPanel from "./requiredactions/panels/CancelledPanel";

    import axios      from 'axios';
    import {EventBus} from '~/utils/event-bus.js';
    // import store from '@/modules/_bootstrap/store'

    export default {
        components : {
            'new-profile-dialog' : NewProfileDialog,

            'project-required-actions-assembled-panel' : RAStartedPanel,
            'project-required-actions-started-panel'   : RAStartedPanel,
            'project-required-actions-ordered-panel'   : RAStartedPanel,
            'project-required-actions-draft-panel'     : RAStartedPanel,
            'project-required-actions-fulfilled-panel' : RAStartedPanel,
            'project-required-actions-cancelled-panel' : RAStartedPanel
        },
        data() {
            return {
                denseView          : true,
                hasProfileData     : false,
                hasInquiryData     : false,
                conversationExists : false,
                frontResponse      : {},
                profile            : {},
                urls               : {
                    intra : process.env.VUE_APP_BOCANI_INTRA_URL,
                    api   : process.env.VUE_APP_BOCANI_API_URL,
                },
                colors             : {
                    quote : {
                        statesBg   : {
                            draft     : 'blue',
                            ordered   : 'orange darken-1',
                            started   : 'purple',
                            assembled : 'amber lighten-1',
                            fulfilled : 'green darken-2',
                            cancelled : 'red darken-4'
                        },
                        statesText : {
                            draft     : 'white',
                            ordered   : 'white',
                            started   : 'white',
                            assembled : 'black',
                            fulfilled : 'black',
                            cancelled : 'white'
                        }
                    }
                },
                labels             : {
                    quote : {
                        states : {
                            draft     : 'Estimation',
                            ordered   : 'Commandé',
                            started   : 'En production',
                            assembled : 'Assemblé',
                            fulfilled : 'Complété',
                            cancelled : 'Annulé'
                        }
                    }
                }
            };
        },
        methods    : {
            findProfile(conversationHandle) {
                axios
                .get(process.env.VUE_APP_BOCANI_API_URL + '/plugins/front/profile/' + conversationHandle + '?auth_secret=' + this.$route.query.auth_secret)
                .then(response => this.handleFindProfileResponse(response));
            },
            showProfile(profileJson) {
                this.profile        = profileJson;
                this.hasProfileData = true;
            },
            showNewProfileDialog(contactJson) {
                this.hasProfileData = false;
                EventBus.$emit('open-new-profile-dialog', contactJson);
            },
            hideProfile() {
                this.profile        = {};
                this.hasProfileData = false;
                this.hasInquiryData = false;
                this.hasQuotesData  = false;
            },
            hideNewProfileDialog() {
                EventBus.$emit('close-new-profile-dialog');
            },
            handleFindProfileResponse(apiResponse) {
                let responseData = apiResponse.data;
                console.log(responseData);

                this.hasProfileData = responseData.id > 1;
                this.hasInquiryData = responseData.inquiries.length > 0;
                this.hasQuotesData  = this.hasProfileData
                    ? responseData.quotes.length > 0
                    : false;

                if (this.hasProfileData) {
                    this.showProfile(responseData);
                } else if (this.hasInquiryData) {
                    let inquiryJson = responseData.inquiries[0];
                    this.showNewProfileDialog({
                        full_name        : inquiryJson.full_name,
                        email            : inquiryJson.email,
                        phone_number     : inquiryJson.phone_number,
                        language         : inquiryJson.language,
                        customer_type_id : 1
                    });
                } else {
                    this.showNewProfileDialog({
                        full_name        : this.frontResponse
                            ? this.frontResponse.display_name
                            : 'Aucun nom',
                        email            : this.frontResponse
                            ? this.frontResponse.handle
                            : 'Aucun courriel',
                        phone_number     : null,
                        language         : 'fr',
                        customer_type_id : 1
                    });
                }
            },
            handleNewProfileFormSuccess(formResponse) {
                this.findProfile(formResponse.email);
            },
            openLink(url) {
                window.Front.openUrl(url);
            },
            newDraft() {
                window.Front.reply({
                    to              : ['someone@somewhere.com'],
                    subject         : 'Optional subject',
                    body            : 'Optional body',
                    attachment_uids : []
                });
            },
          copy(string) {
            navigator.clipboard.writeText(string).then(function() {
              // console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
              // console.error('Async: Could not copy text: ', err);
            });
          }
        },
        computed   : {
            profileId() {
                return this.profile.id;
            },
            intraBaseUrl() {
                return this.urls.intra;
            },
            apiBaseUrl() {
                return this.urls.api;
            }
        },
        filters: {
            uppercase: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.toUpperCase()
            }
        },
        mounted    : function () {
            let app_env = 'local';

            let that = this;

            EventBus.$on('form-success', this.handleNewProfileFormSuccess);

            window.Front.on("conversation", function (data) {
                that.hideNewProfileDialog();
                that.conversationExists = true;
                that.frontResponse      =
                    data.contact.handle === "no-reply@bocani.com"
                        ? data.message.recipients[2]
                        : data.contact;

                that.findProfile(that.frontResponse.handle);
            });

            window.Front.on("no_conversation", function (data) {
                that.hideNewProfileDialog();
                that.hideProfile();
            });

        }
    };
</script>

<style lang="scss">
    .quote-status-border {
        border-left: 3px solid;

        &.draft {
            border-color: #2196F3; // blue
            color: white;
        }

        &.ordered {
            border-color: #FB8C00; // orange darken-1
            color: white;
        }

        &.started {
            border-color: #9C27B0; // purple
            color: white;
        }

        &.assembled {
            border-color: #FFCA28; // amber lighten-1
            color: black;
        }

        &.fulfilled {
            border-color: #388E3C; // green darken-2
            color: black;
        }

        &.cancelled {
            border-color: #B71C1C; // red darken-4
            color: white;
        }
    }
</style>
