<template>
<!--    <v-dialog v-model="dialog" persistent :max-width=500>-->
        <v-card class="elevation-1 ma-2">
            <v-card-title>
                <span class="headline">Créer un nouveau profil</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm6>
                            <v-text-field
                                    label="Nom complet"
                                    required
                                    v-model.trim="contactJson.full_name"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-text-field
                                    label="Email"
                                    required
                                    v-model.trim="contactJson.email"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-text-field
                                    label="Téléphone"
                                    v-model.trim="contactJson.phone_number"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-select
                                    label="Langue"
                                    required
                                    :items="language.items"
                                    v-model="contactJson.language"
                            ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-select
                                    label="Type"
                                    required
                                    :items="customertype.items"
                                    v-model="contactJson.customer_type_id"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                </v-container>
                <small>*indique les champs obligatoire</small>
            </v-card-text>
            <v-card-actions>
                <v-btn
                        color="primary"
                        text
                        @click.native="dialog = false"
                >
                    Fermer
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                        color="success"
                        text
                        :loading="onsubmit.loading"
                        :disabled="onsubmit.loading"
                        @click.native="saveProfile(contactJson)"
                >
                    Sauvegarder
                </v-btn>
            </v-card-actions>
        </v-card>
<!--    </v-dialog>-->
</template>

<script>
    import axios from 'axios';
    import {EventBus} from '~/utils/event-bus.js';

    export default {
        data() {
            return {
                contactJson: {
                    full_name: null,
                    email: null,
                    phone_number: null,
                    language: null,
                    customer_type_id: null
                },
                onsubmit: {
                    loading: false
                },
                language: {
                    items: [
                        {
                            text: 'Français',
                            value: 'fr'
                        },
                        {
                            text: 'Anglais',
                            value: 'en'
                        }
                    ]
                },
                customertype: {
                    items: [
                        {
                            text: 'Particulier',
                            value: 1
                        },
                        {
                            text: 'Professionnel (autre)',
                            value: 2
                        },
                        {
                            text: 'Architecte',
                            value: 3
                        },
                        {
                            text: 'Designer',
                            value: 4
                        },
                        {
                            text: 'Entrepreneur',
                            value: 5
                        },
                        {
                            text: 'Pro domaine des Escaliers',
                            value: 6
                        }
                    ]
                }
            };
        },
        methods: {
            saveProfile() {
                this.onsubmit.loading = true;

                axios({
                    method: 'post',
                    url: process.env.VUE_APP_BOCANI_API_URL + '/plugins/front/profile?auth_secret=' + this.$route.query.auth_secret,
                    data: {
                        'customer-name': this.contactJson.full_name,
                        'customer-type-id': this.contactJson.customer_type_id,
                        'phone-number': this.contactJson.phone_number,
                        email: this.contactJson.email,
                        lang: this.contactJson.language
                    }
                })
                    .then(response => {
                        EventBus.$emit('form-success', response.data);

                        this.dialog = false;
                    })
                    .catch(errors => {
                        let errorsData = errors.response.data;
                        let strFirstError =
                            errorsData[Object.keys(errorsData)[0]][0];
                        // let strErrors = JSON.stringify(errors.response.data);

                        EventBus.$emit('form-error', strFirstError);
                    });

                this.onsubmit.loading = false;
            },
            resetContact() {
                this.contactJson = {
                    full_name: null,
                    email: null,
                    phone_number: null,
                    language: null,
                    customer_type_id: null
                }
            },
            openNewProfileDialogHandler(response) {
                this.contactJson = response;
                this.dialog = true;
            },
            closeNewProfileDialogHandler() {
                this.resetContact();
                this.dialog = false;
            }
        },
        mounted() {
            EventBus.$on('open-new-profile-dialog', this.openNewProfileDialogHandler);
            EventBus.$on('close-new-profile-dialog', this.closeNewProfileDialogHandler);
        }
    };
</script>

<style></style>
