<template>
    <div>
        <v-tabs
            fixed-tabs
            v-model="tab"
            :height="35"
            class="required-actions-tab-item"
        >
            <v-tab key="paymentsAction">
                <v-icon small class="mr-1 red--text" v-show="hasRequiredPaidBalance">error_outline</v-icon>
                Paiements
            </v-tab>

            <v-tab key="measurementsAction">
                <v-icon small class="mr-1 red--text" v-show="false">error_outline</v-icon>
                Mesures
            </v-tab>

            <v-tab key="hasDeliveryDate">
                <v-icon small class="mr-1 red--text" v-show="hasDeliveryDate">error_outline</v-icon>
                Livraison
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <project-required-actions-payments required :project="project"></project-required-actions-payments>
            <project-required-actions-measurements :project="project"></project-required-actions-measurements>
            <project-required-actions-delivery :project="project"></project-required-actions-delivery>
        </v-tabs-items>
    </div>
</template>

<script>
  import MeasurementsAction from '~/components/requiredactions/actions/MeasurementsAction';
  import DeliveryAction from '~/components/requiredactions/actions/DeliveryAction';
  import PaymentsAction from '~/components/requiredactions/actions/PaymentsAction';

  export default {
    components: {
      'project-required-actions-payments': PaymentsAction,
      'project-required-actions-measurements': MeasurementsAction,
      'project-required-actions-delivery': DeliveryAction
    },
    props: {
      project: Object
    },
    data() {
      return {
        tab: null
      };
    },
    computed: {
      hasRequiredPaidBalance() {
        return this.project.amounts.total_balance > 0;
      },
      hasDeliveryDate() {
        return !this.project.project_required_date;
      }
    },
    methods: {}
  };
</script>

<style lang="scss">

    .required-actions-tab-item {
        .v-tab {
            font-size: 12px;
        }
    }

</style>